import { Device, Location, LocationType, MeasurementTransformations, MeasureNameForMongo } from '@agdir/domain';
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { bbox, Feature, FeatureCollection, featureCollection, Point, point, Polygon, polygon } from '@turf/turf';
import { FillPaint, LinePaint, LngLatBoundsLike, Map, MapMouseEvent } from 'mapbox-gl';
import { BehaviorSubject, combineLatest, from, merge, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchAll, switchMap } from 'rxjs/operators';
import { DigifarmService } from '../digifarms/digifarms.service';
import {
	calcBounds,
	DEFAULT_FITBOUND_LOCATION,
	DEFAULT_FITBOUNDS_OPTIONS,
	DEFAULT_MAP_CONFIG,
	LocationMapSettings,
	LocationMergeResult,
	LocationSplitResult,
	makeMapConfig,
} from '../utils';
import { MapPolygonComponent } from './map-polygon.component';

const SELECTED_POLYGON_COLOR = '#98cd78';

@Component({
	selector: 'agdir-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
})
export class AgdirMapComponent {
	@Input() newMarker?: Point;
	@Input() showZoningLayers = false;
	@Input() showFullscreen = false;
	@Input() setMarkerOnClick = false;
	@Input() disableDefaultUI = false;
	@Input() fillColor = '#744f29';
	@Input() displayLocationNames = false;
	@Input() showLocateMeControl = false;
	@Input() showGeocoderControl = false;
	@Input() showDrawingTools = false;
	@Input() showSplitMergeTools = false;
	@Input() showDetectFieldsControl = false;
	@Input() fieldSelectionMode = false;
	@Input() drawingMode = false;
	@Input() editMode = false;
	@Input() boundToDevice = false;
	@Input() hideToolbar = false;

	@Output() loading = new EventEmitter<boolean>();
	@Output() newMarkerPosition = new EventEmitter<Point | undefined>();
	@Output() locationSplitted = new EventEmitter<LocationSplitResult>();
	@Output() locationMerged = new EventEmitter<LocationMergeResult>();
	@Output() locationDrawn = new EventEmitter<Feature<Polygon>>();
	@Output() locationUpdated = new EventEmitter<Feature<Polygon>>();
	@Output() fieldsDetected = new EventEmitter<FeatureCollection<Polygon> | null>();
	@Output() objectClick = new EventEmitter<[Location | Device, Feature<Polygon> | null]>();
	@Output() mapReady = new EventEmitter<Map>();
	@ContentChildren(MapPolygonComponent) projectedPolygons?: QueryList<MapPolygonComponent>;

	get hasAnyZone() {
		return this.unfilteredLocations.some((s) => s.locationType === LocationType.fieldZone);
	}

	unfilteredLocations: Location[] = [];

	locations$ = new ReplaySubject<Location[]>(1);
	devices$ = new BehaviorSubject<Device[]>([]);
	myPositionTrigger$ = new ReplaySubject<Point>(1);

	myPosition$ = merge([
		this.myPositionTrigger$,
		from(
			new Promise<Point>((resolve) =>
				this.window.navigator.geolocation.getCurrentPosition((position) =>
					resolve({ type: 'Point', coordinates: [position.coords.longitude, position.coords.latitude] }),
				),
			),
		),
	]).pipe(switchAll());

	locationMap$: Observable<LocationMapSettings> = combineLatest([this.locations$, this.devices$]).pipe(
		switchMap(([locations, devices]) => {
			const boundToDevice = this.boundToDevice && !!devices.at(0)?.geoPoint;

			if (!boundToDevice && locations?.length > 0 && locations[0]?.geoJson?.geometry) {
				return of(locations.map((s) => s.geoJson?.geometry));
			}
			return this.getBrowserGeoLocation(this.newMarker || devices.at(0)?.geoPoint);
		}),
		map((g) => makeMapConfig(g as Polygon[])),
	);
	mapInstance?: Map;
	private selectedPolygon?: Feature<Polygon>;

	constructor(
		private matDialog: MatDialog,
		private window: Window,
	) {}

	@Input() set locations(locations: Location[]) {
		this.unfilteredLocations = locations?.filter((s) => !!s) || [];
		this.locations$.next(this.unfilteredLocations);
	}

	@Input() set devices(devices: Device[]) {
		this.devices$.next(devices?.filter((device) => device?.geoPoint) ?? []);
	}

	onMapReady(map: Map) {
		this.mapInstance = map;
		this.mapReady.emit(map);
		if (this.projectedPolygons && this.projectedPolygons.length > 0) {
			const polygonsToFit = this.projectedPolygons.map((s) => (s.feature as Feature<Polygon>).geometry);
			this.mapInstance?.fitBounds(calcBounds(polygonsToFit), {
				...DEFAULT_FITBOUNDS_OPTIONS,
				zoom: DEFAULT_MAP_CONFIG.zoom,
				maxZoom: DEFAULT_MAP_CONFIG.maxZoom,
			});
		}
	}

	locationZoneFilterChange(showFieldZones: boolean) {
		this.locations$.next(this.unfilteredLocations.filter((s) => showFieldZones || s.locationType !== LocationType.fieldZone));
	}

	mainLocationFilterChange(showMainFields: boolean) {
		this.locations$.next(this.unfilteredLocations.filter((s) => showMainFields || s.locationType !== LocationType.field));
	}

	locationFillProps(location: Location): FillPaint {
		return {
			'fill-color': location.locationType === LocationType.fieldZone ? location.color || '#e5d989' : location.color || this.fillColor,
			'fill-opacity': location.locationType === LocationType.fieldZone ? 0.5 : 0.8,
		};
	}

	locationLineProps(location: Location): LinePaint {
		return {
			'line-color': '#ffffff',
			'line-width': location.locationType === LocationType.fieldZone ? 2 : 3,
			'line-dasharray': location.locationType === LocationType.fieldZone ? [1, 2] : [1],
		};
	}

	projectedFillPaint(projected: MapPolygonComponent): FillPaint {
		return { 'fill-color': 'white', 'fill-opacity': 0.8, ...projected.fillPaint };
	}

	projectedOutlinePaint(projected: MapPolygonComponent): LinePaint {
		return { 'line-color': '#ffffff', 'line-width': 3, ...projected.outlinePaint };
	}

	projectedSymbolPaint(projected: MapPolygonComponent) {
		const baseSymbolFill = {
			'text-field': projected.name,
			...projected.symbolPaint,
		};

		if (!projected.deletable) {
			return baseSymbolFill;
		}
		return { 'icon-image': 'delete-icon', 'icon-size': 0.05, 'icon-offset': [0, 450], ...baseSymbolFill };
	}

	onGeoLocate($event: Point) {
		this.mapInstance?.fitBounds(
			bbox(featureCollection(Array(2).fill(point([$event?.coordinates[0], $event?.coordinates[1]])))) as LngLatBoundsLike,
			{
				...DEFAULT_FITBOUNDS_OPTIONS,
				zoom: DEFAULT_MAP_CONFIG.zoom,
				maxZoom: DEFAULT_MAP_CONFIG.maxZoom,
			},
		);

		this.myPositionTrigger$.next($event);
	}

	showZones(show: boolean) {
		if (this.mapInstance) {
			if (show) {
				DigifarmService.addZoningLayers(this.mapInstance);
			} else {
				DigifarmService.removeZoningLayers(this.mapInstance);
			}
		}
	}

	onLocationSplitted(splitResult: LocationSplitResult) {
		this.locationSplitted.emit(splitResult);
		if (this.selectedPolygon) {
			this.selectPolygon(this.selectedPolygon, false);
		}
	}

	onLocationMerged(mergeResult: LocationMergeResult) {
		this.locationMerged.emit(mergeResult);
	}

	onMapBoxDraw(event: MapboxDraw.DrawCreateEvent) {
		this.locationDrawn.emit(event.features[0] as Feature<Polygon>);
	}

	onMapBoxUpdated(event: MapboxDraw.DrawUpdateEvent) {
		this.locationUpdated.emit(event.features[0] as Feature<Polygon>);
	}

	onMarkerClick(device: Device) {
		this.objectClick.emit([device, null]);
	}

	onLocationClick(features: Feature<Polygon>[], location: Location) {
		if (this.fieldSelectionMode) {
			if (this.selectedPolygon) {
				this.selectPolygon(this.selectedPolygon, false);
			}
			this.selectedPolygon = features[0];
			this.selectPolygon(this.selectedPolygon, true);
		}
		this.objectClick.emit([location, this.selectedPolygon ?? null]);
	}

	clearNewMarker() {
		this.newMarker = undefined;
		this.newMarkerPosition.emit(undefined);
	}

	onMapClick($ev: MapMouseEvent) {
		if (this.setMarkerOnClick && $ev.lngLat) {
			this.setAndEmit({ type: 'Point', coordinates: [$ev.lngLat.lng, $ev.lngLat.lat] });
		}
	}

	setAndEmit(newCoords: Point): void {
		this.newMarker = newCoords;
		this.newMarkerPosition.emit(this.newMarker);
	}

	getTemperature(device: Device) {
		const oneOfTemperatures =
			MeasurementTransformations.getMeasure(MeasureNameForMongo.SOIL_TEMPERATURE, device.latestMeasurements) ||
			MeasurementTransformations.getMeasure(MeasureNameForMongo.AIR_TEMPERATURE, device.latestMeasurements);
		return oneOfTemperatures ? MeasurementTransformations.getMeasureValueString(oneOfTemperatures) : '';
	}

	private getBrowserGeoLocation(newMarker?: Point): Promise<Polygon[]> {
		const [lng, lat] = newMarker?.coordinates ?? [];
		if (lng && lat) {
			return Promise.resolve([this.createBoundsPolygon(lng, lat)]);
		}
		return new Promise((resolve) => {
			this.window.navigator.geolocation.getCurrentPosition(
				(pos) => resolve([this.createBoundsPolygon(pos.coords.longitude, pos.coords.latitude)]),
				() => resolve([this.createBoundsPolygon(DEFAULT_FITBOUND_LOCATION.lng, DEFAULT_FITBOUND_LOCATION.lat)]),
			);
		});
	}

	private createBoundsPolygon(lng: number, lat: number) {
		return polygon([Array(4).fill([lng, lat])]).geometry;
	}

	private selectPolygon(feature: Feature<Polygon>, selected: boolean) {
		const polygonId = (feature as any).source;
		this.mapInstance?.setPaintProperty(
			polygonId.replace('polygon', 'polygon-fill'),
			'fill-color',
			selected ? SELECTED_POLYGON_COLOR : this.fillColor,
		);
	}
}
